<template>
    <div class="tab-content">
        <div class="tab-header flexed">
            <div class="search-wrapper">
                <v-text-field
                    class="styled-field"
                    label="Search"
                    clearable
                    placeholder="Enter terms..."
                    @input="handleSearch" />
            </div>
        </div>
        <div
            class="sticky-container"
            @scroll="handleScroll">
            <platform-campaigns
                v-for="(platform, index) in filteredPlatforms"
                :key="platform"
                :search="search"
                :spend-option="spendOption"
                :platform="platform"
                :index="index"
                :scroll-position="scrollPosition"
                @spend-option-changed="handleDropdownChange" />
            <div
                id="channel-totals"
                class="py-2">
                <span class="w2 py-3 d-inline-block" />
                <span class="w2 py-3 d-inline-block" />
                <span class="w30 py-3 d-inline-block" />
                <span class="text-xs-center w5 py-3 d-inline-block font-weight-bold">
                    <span>Total(s)</span>
                </span>
                <span class="text-xs-center w10 py-3 d-inline-block font-weight-bold">
                    <span>{{ formatNumber(sumAllMetricsTotals.spend, 'currency') }}</span>
                </span>
                <span class="text-xs-center w4 py-3 d-inline-block font-weight-bold">
                    <span>{{ formatNumber(sumAllMetricsTotals.frequency, 'number') }}</span>
                </span>
                <span class="text-xs-center w10 py-3 d-inline-block font-weight-bold">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.impressions, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cpm, 'currency') }}</span>
                    </span>
                </span>
                <span
                    class="text-xs w7 py-3 d-inline-block font-weight-bold"
                    style="margin-left: 10px;">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.clicks, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cost_per_click, 'currency') }}</span>
                    </span>
                </span>
                <span class="text-xs w7 py-3 d-inline-block font-weight-bold">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.view_content, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cost_per_view_content, 'currency') }}</span>
                    </span>
                </span>
                <span class="text-xs w7 py-3 d-inline-block font-weight-bold">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.landing_page_view, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cost_per_landing_page_view, 'currency') }}</span>
                    </span>
                </span>
                <span class="text-xs w7 py-3 d-inline-block font-weight-bold">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.leads, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cost_per_lead, 'currency') }}</span>
                    </span>
                </span>
                <span class="text-xs w7 py-3 d-inline-block font-weight-bold">
                    <span class="divided-wrapper">
                        <span class="span-set divided">{{ formatNumber(sumAllMetricsTotals.sales, 'number') }}</span>
                        <span class="span-set">{{ formatNumber(sumAllMetricsTotals.cost_per_sale, 'currency') }}</span>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
import PlatformCampaigns from './PlatformCampaigns';
import { mapGetters, mapState } from 'vuex';
import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';

export default {
    name: 'Campaigns',
    components: {
        PlatformCampaigns
    },
    props: {
        platforms: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            search: '',
            spendOption: 'All',
            scrollPosition: 0,
            managedCampaigns: []
        };
    },
    computed: {
        ...mapState({
            selectedCampaigns: (state) => state.playbookManagement.selectedCampaigns
        }),
        ...mapGetters('playbookManagement', [
            'getNormalizedMetricTotals'
        ]),
        managingCampaigns() {
            return this.$store.state.playbookManagement.managingCampaigns;
        },
        sumAllMetricsTotals() {
            let totals = {
                spend: 0,
                impressions: 0,
                cpm: 0,
                clicks: 0,
                cost_per_click: 0,
                leads: 0,
                cost_per_lead: 0,
                sales: 0,
                cost_per_sale: 0,
                frequency: 0,
                landing_page_view: 0,
                cost_per_landing_page_view: 0,
                view_content: 0,
                cost_per_view_content: 0,
            };
            this.platforms.forEach(item => {
                var metrics = this.getNormalizedMetricTotals(item);
                if (metrics) {
                    totals.spend += metrics.spend ?? 0;
                    totals.impressions += metrics.impressions ?? 0;
                    totals.cpm += metrics.cpm ?? 0;
                    totals.clicks += metrics.clicks ?? 0;
                    totals.cost_per_click += metrics.cost_per_click ?? 0;
                    totals.leads += metrics.leads ?? 0;
                    totals.cost_per_lead += metrics.cost_per_lead ?? 0;
                    totals.sales += metrics.sales ?? 0;
                    totals.cost_per_sale += metrics.cost_per_sale ?? 0;
                    totals.frequency += metrics.frequency ?? 0;
                    totals.landing_page_view += metrics.landing_page_view ?? 0;
                    totals.cost_per_landing_page_view += metrics.cost_per_landing_page_view ?? 0;
                    totals.view_content += metrics.view_content ?? 0;
                    totals.cost_per_view_content += metrics.cost_per_view_content ?? 0;
                }
            });
            return totals;
        },
        filteredPlatforms() {
            return this.managingCampaigns ? this.managedCampaigns : this.platforms;
        }
    },
    mounted() {
        this.managedCampaigns = this.platforms.filter(platform => (
            this.selectedCampaigns[platform].length
        ));
    },
    methods: {
        formatNumber(number, type) {
            const formats = getNumberFormats();

            if (!number && type === 'number') {
                return 0;
            }

            return numeral(number).format(formats[type]);
        },
        handleDropdownChange(selectedValue) {
            this.spendOption = selectedValue;
        },
        handleScroll(event) {
            const container = event.target;
            this.scrollPosition = container.scrollTop;
        },
        handleSearch: debounce(function(input) {
            this.search = input;
        }, 400)
    }
};
</script>

<style>
.sticky-container {
    height:800px;
    overflow: auto;
    padding: 0 20px;
}
.sticky-container::-webkit-scrollbar {
    display: none;
}
</style>
